import React from 'react';
import trypLogo from '../trypLogo.png';

const Header = () => {
  return (
    <header style={styles.header}>
      <img src={trypLogo} alt="Logo" style={{float: 'left', margin: '10px', height:'60px',width:'180px'}} />

    </header>
  );
}

export default Header;
const styles = {
    header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100px',
    },
    logo: {
      height: '80px',
      width: '80px',
    },
  };