import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import apple from './apple.png'
import android from './android.png'
import phonesTryp from './phonesTryp.png'
function App() {
  return (
    <div >

      <Header />

      <div className='button-shop'>
        <a className='shop-button'
        href="https://tryp.shop"
        >
          <h3 style={{ color: 'white',textDecoration:'none' }}>Tienda</h3>
        </a>
      </div>
      <div className='green-text'>
        <h3 className='big-green-text'>Cannabis & CBD</h3>
        <h4 className='small-green-text'>
          directo a tu puerta
        </h4>
        <h4 className='small-text'>
          Los mejores productos de cannabis en México, directo a tu puerta.
        </h4>
        <h4 className='small-text'>
          ¡Haz un viaje a tu interior!

        </h4>
        <a
          href="https://apps.apple.com/app/apple-store/id1569254336?pt=123079101&amp;ct=trypmx&amp;mt=8">
          <img src={apple}
          className='download-button'
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.oldgtryapp&amp;pli=1"
        >
          <img 
          src={android}
          className='download-button'
          />
        </a>
        <img 
          src={phonesTryp}
          className='phones-button'
          />
      </div>

    </div>
  );
}

export default App;
